.logo-icon {
  flex-shrink: 0;
  width: 177px;
  height: 32px;
  position: relative;
  overflow: hidden;
}

.button-child {
  background-color: var(--text-brand-primary-brand-primary);
  z-index: 0;
  width: 0;
  height: 1px;
  position: relative;
}

.metamask-icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  display: none;
  position: relative;
  overflow: hidden;
}

.button1 {
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  font-family: var(--heading-heading-xs);
  color: var(--text-neutral-primary-inverse);
  text-align: left;
  position: relative;
}

.metamask-parent {
  padding: var(--xs) var(--l1);
  justify-content: flex-start;
  align-items: center;
  gap: var(--xs);
  z-index: 1;
  flex-direction: row;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
}

.button, .button2 {
  cursor: pointer;
  background-color: #0000;
  border: 0;
}

.button {
  padding: 0 var(--s) var(--xs1);
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 124px;
  height: 48px;
  display: flex;
  position: relative;
}

.button2 {
  width: 126px;
}

.button2, .button4, .button6, .button8 {
  padding: 0 var(--s) var(--xs1);
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 48px;
  display: flex;
  position: relative;
}

.button4 {
  cursor: pointer;
  background-color: #0000;
  border: 0;
  width: 158px;
}

.button6, .button8 {
  width: 163px;
}

.button8 {
  cursor: pointer;
  background-color: #0000;
  border: 0;
  width: 96px;
}

.button-parent {
  flex-direction: row;
  align-items: flex-start;
}

.button-parent, .button10, .metamask-parent2 {
  justify-content: flex-start;
  display: flex;
}

.metamask-parent2 {
  border-radius: var(--m1);
  border: 1px solid var(--text-brand-primary-brand-primary);
  padding: var(--xs) var(--l1);
  align-items: center;
  gap: var(--xs);
  flex-direction: row;
}

.metamask-parent2:hover {
  border: 1px solid var(--color-mediumseagreen);
  box-sizing: border-box;
}

.button10 {
  cursor: pointer;
  background-color: #0000;
  border: 0;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.header, .logo-parent {
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.header {
  padding: var(--padding-xl) 0;
  flex-direction: column;
  justify-content: flex-start;
}

.tired-of-going, .unlock-your-longevity {
  align-self: stretch;
  line-height: 64px;
  position: relative;
}

.tired-of-going {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-brand-primary-brand-primary-light);
  line-height: 24px;
}

.hero-text {
  justify-content: flex-start;
  align-items: center;
  gap: var(--m);
  flex-direction: column;
  width: 100%;
  max-width: 812px;
  display: flex;
}

.button13 {
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  font-family: var(--heading-heading-xs);
  color: var(--background-neutral-secondary);
  text-align: left;
  position: relative;
}

.button12, .metamask-parent3 {
  border-radius: var(--m1);
  justify-content: flex-start;
  display: flex;
}

.metamask-parent3 {
  padding: var(--xs) var(--l1);
  align-items: center;
  gap: var(--xs);
  flex-direction: row;
}

.button12 {
  cursor: pointer;
  background-color: var(--text-brand-primary-brand-primary);
  border: 0;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.button12:hover {
  background-color: var(--color-mediumseagreen);
}

.video-1-icon {
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  height: 566px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.play-child, .play-item {
  border-radius: var(--full);
  position: absolute;
}

.play-child {
  backdrop-filter: var(--background-blur-xs);
  background-color: var(--background-overlay-white-light);
  width: 116px;
  height: 116px;
  top: 0;
  left: calc(50% - 58px);
}

.play-item {
  background-color: var(--background-overlay-black-strong);
  border: 2px solid var(--text-brand-primary-brand-primary);
  box-sizing: border-box;
  width: 96px;
  height: 96px;
  top: 10px;
  left: calc(50% - 48px);
}

.play-inner {
  border-radius: var(--br-9xs);
  object-fit: contain;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 40px;
  left: calc(50% - 18px);
}

.play {
  cursor: pointer;
  background-color: #0000;
  border: 0;
  width: 116px;
  height: 116px;
  padding: 0;
  position: absolute;
  top: 225px;
  left: 446px;
}

.video-1-parent {
  width: 1008px;
  height: 566px;
  position: relative;
}

.header-parent, .hero {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.hero {
  gap: var(--gap-45xl);
  text-align: center;
  font-size: var(--heading-heading-3xl-size);
}

.header-parent {
  gap: 60px;
}

.health-isnt-one-size-fits-all {
  font-size: var(--heading-heading-xs-size);
  justify-content: center;
  align-items: center;
  width: 787px;
  line-height: 24px;
  display: flex;
  position: relative;
}

.why-longevity-parent {
  justify-content: flex-start;
  align-items: center;
  gap: var(--s);
  flex-direction: column;
  display: flex;
}

.icon-1 {
  object-fit: cover;
  width: 64px;
  height: 64px;
  position: relative;
}

.expert-level-knowledge {
  align-self: stretch;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  position: relative;
}

.frame-child {
  background-color: var(--background-brand-secondary-brand-secondary);
  width: 48px;
  height: 1px;
  position: relative;
}

.information-on-supplements {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-tertiary-inverse);
  align-self: stretch;
  line-height: 24px;
  position: relative;
}

.icon-1-parent {
  justify-content: flex-start;
  align-items: center;
  gap: var(--m);
  flex-direction: column;
  flex: 1;
  display: flex;
}

.frame-group, .why-longevity {
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--xl1);
  font-size: var(--heading-heading-m-size);
  color: var(--text-neutral-primary-inverse);
  flex-direction: row;
  display: flex;
}

.why-longevity {
  justify-content: flex-start;
  align-items: center;
  gap: var(--xl4);
  text-align: center;
  font-size: var(--heading-heading-2xl-size);
  color: var(--text-brand-primary-brand-primary-light);
  flex-direction: column;
}

.frame-parent, .heres-how-our {
  font-size: var(--heading-heading-xs-size);
}

.frame-parent {
  color: var(--text-neutral-primary-inverse);
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 160px;
  display: flex;
}

.heres-how-our {
  line-height: 24px;
  position: relative;
}

.mask-group-icon {
  object-fit: cover;
  width: 687px;
  height: 687px;
  position: absolute;
  top: 4px;
  left: calc(50% - 336px);
}

.group-icon {
  width: 812px;
  height: 696px;
  position: absolute;
  top: 0;
  left: 16px;
}

.b, .provide-your-information {
  justify-content: center;
  align-items: center;
  width: 244px;
  line-height: 32px;
  display: flex;
  position: relative;
}

.provide-your-information {
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
  line-height: 28px;
}

.parent {
  justify-content: flex-start;
  align-items: center;
  gap: var(--xs1);
  flex-direction: column;
  display: flex;
}

.frame-child1 {
  background-color: var(--background-brand-secondary-brand-secondary);
  width: 48px;
  height: 1px;
  display: none;
  position: relative;
}

.ask-questions-get {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-tertiary-inverse);
  justify-content: center;
  align-items: center;
  width: 279px;
  line-height: 24px;
  display: none;
  position: relative;
}

.frame-container {
  justify-content: flex-start;
  align-items: center;
  gap: var(--s);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.b1 {
  align-self: stretch;
  line-height: 32px;
  position: relative;
}

.create-custom-charts {
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
  width: 279px;
  line-height: 28px;
  display: inline-block;
  position: relative;
}

.frame-parent1, .group {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.group {
  align-self: stretch;
  gap: var(--xs1);
}

.frame-parent1 {
  gap: var(--s);
  position: absolute;
  top: 512px;
  left: 0;
}

.receive-highest-quality {
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
  justify-content: center;
  align-items: center;
  width: 312px;
  line-height: 28px;
  display: flex;
  position: relative;
}

.frame-parent2, .frame-parent3 {
  justify-content: flex-start;
  align-items: center;
  gap: var(--s);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 512px;
  left: 502px;
}

.frame-parent3 {
  top: 64px;
  left: 564px;
}

.mask-group-parent {
  font-size: var(--heading-heading-m-size);
  color: var(--text-brand-primary-brand-primary);
  width: 828px;
  height: 696px;
  position: relative;
}

.how-it-works {
  justify-content: flex-start;
  align-items: center;
  gap: var(--xl4);
  text-align: center;
  flex-direction: column;
  display: flex;
}

.reliable-and-accurate {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #38eb9a, #20beff);
  -webkit-background-clip: text;
  align-self: stretch;
  font-weight: 600;
  line-height: 48px;
  position: relative;
}

.reliable-and-accurate-parent {
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-tertiary);
  padding: var(--xl2) var(--xl4);
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: var(--xl2);
  flex-direction: column;
  flex: 1;
  display: flex;
}

.layer-4-icon {
  flex-shrink: 0;
  width: 68px;
  height: 68px;
  position: relative;
  overflow: hidden;
}

.your-data-is-protected-parent {
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-tertiary);
  padding: var(--xl2) var(--xl4);
  justify-content: flex-start;
  align-items: center;
  gap: var(--xl2);
  flex-direction: column;
  flex: 1;
  display: flex;
}

.banners {
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-13xl);
  text-align: center;
  font-size: var(--heading-heading-xl-size);
  flex-direction: row;
  display: flex;
}

.ask-learn-live {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #38eb9a, #20beff);
  -webkit-background-clip: text;
  line-height: 56px;
  position: relative;
}

.group-child {
  background: radial-gradient(50% 50%, #38eb9abf, #1a1a1a00);
  border-radius: 50%;
  width: calc(100% + 122.1px);
  height: 218.1px;
  display: none;
  position: absolute;
  top: calc(50% - 95.7px);
  left: -47.3px;
  right: -74.8px;
}

.talk-icon {
  object-fit: cover;
  width: 96px;
  height: 96px;
  position: absolute;
  top: 0;
  left: 0;
}

.ellipse-parent {
  width: 96px;
  height: 96px;
  position: relative;
}

.talk-to-your {
  align-self: stretch;
  line-height: 28px;
  position: relative;
}

.features-card-alt, .group-parent, .talk-to-your-ai-coach-parent {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.talk-to-your-ai-coach-parent {
  align-self: stretch;
  gap: var(--s);
}

.features-card-alt, .group-parent {
  gap: var(--m);
}

.group-parent {
  align-self: stretch;
}

.features-card-alt {
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-tertiary);
  box-sizing: border-box;
  padding: var(--padding-13xl);
  width: 280px;
  height: 373px;
}

.ask-questions-naturally {
  margin: 0;
}

.features-card-alt-parent {
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--l1);
  text-align: center;
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
  flex-flow: wrap;
  display: flex;
}

.features {
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: var(--xl4);
  flex-direction: column;
  display: flex;
}

.youre-not-alone {
  font-size: var(--heading-heading-xs-size);
  align-self: stretch;
  line-height: 24px;
  position: relative;
}

.community, .join-our-community-parent {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.join-our-community-parent {
  gap: var(--s);
}

.community {
  border-radius: var(--xl);
  padding: var(--xl1);
  background-image: url("community@3x.ac6dcc0d.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  gap: 73px;
}

.arrow-buttons-icon {
  object-fit: contain;
  width: 48px;
  height: 40px;
  position: relative;
}

.email-input-child {
  background-color: var(--text-neutral-quaternary-inverse);
  align-self: stretch;
  height: 1px;
  position: relative;
}

.email-input {
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-end;
  gap: var(--s);
  flex-direction: column;
  display: flex;
}

.community-parent, .newsletter {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.newsletter {
  border-radius: var(--xl);
  padding: var(--xl1);
  gap: var(--xl2);
  background-image: url("newsletter@3x.b918da73.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  flex: 1;
}

.community-parent {
  align-self: stretch;
  gap: var(--gap-13xl);
  font-size: var(--heading-heading-xl-size);
  color: var(--text-neutral-tertiary-inverse);
  flex-direction: row;
}

.mask-group-icon1 {
  width: 592px;
  height: 349px;
  position: relative;
}

.as-a-fitness, .sarah-m-fitness-container {
  width: 592px;
  line-height: 28px;
  display: inline-block;
  position: relative;
}

.sarah-m-fitness-container {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-tertiary-inverse);
  line-height: 24px;
}

.as-a-fitness-enthusiast-i-alw-parent {
  border-top: 1px solid var(--text-brand-primary-brand-primary);
  border-bottom: 1px solid var(--text-brand-primary-brand-primary);
  padding: var(--xl4) 0;
  justify-content: center;
  align-items: center;
  gap: var(--xl2);
  flex-direction: column;
  display: flex;
}

.mask-group-group, .testimonials {
  justify-content: flex-start;
  display: flex;
}

.mask-group-group {
  text-align: center;
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
  flex-direction: row;
  align-items: flex-start;
  gap: 104px;
}

.testimonials {
  align-items: center;
  gap: var(--xl4);
  flex-direction: column;
  width: 1920px;
  overflow: hidden;
}

.all-your-health {
  align-self: stretch;
  line-height: 40px;
  position: relative;
}

.all-your-health-data-in-one-pl-parent {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--xl2);
  flex-direction: column;
  flex: 1;
  display: flex;
}

.rectangle-icon {
  border-radius: var(--xl);
  object-fit: cover;
  width: 488px;
  height: 300px;
  position: relative;
}

.rectangle-parent {
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: var(--xl4);
  flex-direction: row;
  display: flex;
}

.join-waitlist-parent {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--s);
  flex-direction: column;
  flex: 1;
  display: flex;
}

.email-input1, .newsletter1 {
  align-items: flex-end;
  display: flex;
}

.email-input1 {
  justify-content: flex-start;
  gap: var(--s);
  flex-direction: column;
  flex: 1;
}

.newsletter1 {
  border-radius: var(--xl);
  padding: var(--xl1);
  justify-content: flex-end;
  align-self: stretch;
  gap: var(--xl2);
  font-size: var(--heading-heading-xl-size);
  color: var(--text-neutral-tertiary-inverse);
  background-image: url("newsletter1@3x.8da5aff9.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: row;
}

.coming-soon, .frame-parent4 {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.frame-parent4 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--xl5);
  font-size: var(--heading-heading-l-size);
  color: var(--text-neutral-primary-inverse);
}

.coming-soon {
  align-items: center;
  gap: var(--xl4);
  width: 100%;
  max-width: 1216px;
}

.nulla-pulvinar-odio {
  flex-shrink: 0;
  align-items: center;
  width: 655px;
  line-height: 32px;
  display: flex;
  position: relative;
}

.subtract-icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
}

.nulla-pulvinar-odio-vel-dolor-parent {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 720px;
  display: flex;
}

.nulla-pulvinar-odio1 {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-tertiary-inverse);
  align-items: center;
  width: 655px;
  line-height: 24px;
  display: flex;
  position: relative;
}

.faq1 {
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-quaternary);
  padding: var(--padding-21xl);
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-5xl);
  flex-direction: column;
  display: flex;
}

.nulla-pulvinar-odio3 {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-tertiary-inverse);
  align-items: center;
  width: 655px;
  line-height: 24px;
  display: none;
  position: relative;
}

.faq-parent {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--heading-heading-m-size);
  color: var(--text-neutral-primary-inverse);
  flex-direction: column;
  display: flex;
}

.faq {
  align-items: center;
  gap: var(--gap-61xl);
  text-align: center;
}

.body, .faq, .frame-parent12 {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.body {
  padding: 0 var(--l1);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-181xl);
  width: 100%;
  max-width: 1280px;
}

.frame-parent12 {
  align-items: flex-start;
  gap: var(--xl2);
  width: 477px;
}

.logo-icon1 {
  flex-shrink: 0;
  width: 208px;
  height: 38px;
  position: relative;
  overflow: hidden;
}

.get-in-touch, .infolongevityac {
  line-height: 28px;
  position: relative;
}

.infolongevityac {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-brand-primary-brand-primary);
  text-align: center;
  line-height: 24px;
  text-decoration: underline;
}

.get-in-touch-parent {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--s);
  flex-direction: column;
  display: flex;
}

.twitter-parent {
  justify-content: center;
  align-items: flex-end;
  gap: var(--m);
  flex-direction: row;
  display: flex;
}

.frame-parent13 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--l1);
  flex-direction: column;
  display: flex;
}

.frame-parent11, .logo-group {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.logo-group {
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
  flex-direction: column;
  align-self: stretch;
}

.frame-parent11 {
  flex-direction: row;
  width: 100%;
  max-width: 1216px;
}

.footer1 {
  border-radius: var(--xl3);
  background-color: var(--background-neutral-secondary);
  padding: var(--padding-21xl);
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 920px;
  max-width: 920px;
  display: none;
}

.frame-parent15 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--xl2);
  flex-direction: column;
  width: 551px;
  display: flex;
}

.infolongevityac1 {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-brand-primary-brand-primary);
  text-align: center;
  line-height: 24px;
  text-decoration: underline;
  position: relative;
}

.infolongevityac1:hover {
  color: var(--color-mediumseagreen);
}

.vector-icon {
  width: 85%;
  max-width: 100%;
  height: 87.5%;
  max-height: 100%;
  position: absolute;
  inset: 6.25% 7.5%;
  overflow: hidden;
}

.twitter {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.vector-icon1, .vector-icon2 {
  width: 92.5%;
  max-width: 100%;
  height: 92.5%;
  max-height: 100%;
  position: absolute;
  inset: 5% 2.5% 2.5% 5%;
  overflow: hidden;
}

.vector-icon2 {
  width: 100%;
  height: 100%;
  inset: 0;
}

.frame-parent16 {
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--xl4);
  flex-direction: row;
  display: flex;
}

.logo-container {
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 151px;
  display: flex;
}

.frame-parent14 {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1216px;
  display: flex;
}

.footer2 {
  border-radius: var(--xl3);
  background-color: var(--background-neutral-secondary);
  padding: var(--padding-21xl);
  box-sizing: border-box;
  color: var(--text-neutral-tertiary-inverse);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  height: 417px;
  display: flex;
}

.copyright-2024 {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-quaternary-inverse);
  line-height: 24px;
  position: relative;
}

.footer {
  padding: 0 var(--padding-21xl);
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: var(--xl2);
  font-size: var(--heading-heading-xl-size);
  color: var(--text-neutral-secondary-inverse);
  flex-direction: column;
  display: flex;
}

.logo-icon3 {
  flex-shrink: 0;
  width: 132px;
  height: 24px;
  position: relative;
  overflow: hidden;
}

.button20, .button22, .button24, .button26 {
  padding: 0 var(--s) var(--xs1);
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 124px;
  height: 48px;
  display: flex;
  position: relative;
}

.button22, .button24, .button26 {
  width: 126px;
}

.button24, .button26 {
  width: 104px;
}

.button26 {
  width: 96px;
}

.button-group, .metamask-parent11 {
  flex-direction: row;
  justify-content: flex-start;
}

.button-group {
  align-items: flex-start;
  display: none;
}

.metamask-parent11 {
  border-radius: var(--m1);
  border: 1px solid var(--text-brand-primary-brand-primary);
  padding: var(--xs) var(--l1);
  align-items: center;
  gap: var(--xs);
  display: flex;
}

.button28 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: none;
}

.menu-child {
  border-radius: var(--br-980xl);
  width: 14px;
  height: 0;
  position: relative;
}

.header1, .menu {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.menu {
  justify-content: center;
  gap: 6px;
  width: 16px;
  height: 16px;
}

.header1 {
  padding: var(--padding-xs) 0;
  justify-content: flex-start;
  align-self: stretch;
}

.unlock-your-longevity1 {
  align-self: stretch;
  font-weight: 600;
  line-height: 48px;
  position: relative;
}

.hero-text1 {
  padding: 0 var(--padding-xl);
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: var(--m);
  text-align: center;
  font-size: var(--heading-heading-xl-size);
  flex-direction: column;
  display: flex;
}

.video-with-mask {
  object-fit: cover;
  width: 347.3px;
  height: 195px;
  position: absolute;
  top: 0;
  left: 0;
}

.play-child1, .play-child2 {
  border-radius: var(--full);
  position: absolute;
}

.play-child1 {
  backdrop-filter: var(--background-blur-xs);
  background-color: var(--background-overlay-white-light);
  width: calc(100% - 210px);
  height: 80px;
  top: 18px;
  left: 105px;
  right: 105px;
}

.play-child2 {
  background-color: var(--background-overlay-black-strong);
  border: 2px solid var(--text-brand-primary-brand-primary);
  box-sizing: border-box;
  width: calc(100% - 224px);
  height: 66px;
  top: 25px;
  left: 112px;
  right: 112px;
}

.polygon-icon {
  border-radius: var(--br-9xs);
  object-fit: contain;
  width: calc(100% - 262px);
  max-width: 100%;
  height: 28px;
  position: absolute;
  top: 44px;
  left: 159px;
  right: 103px;
  overflow: hidden;
}

.some-text-goes, .some-text-goes1 {
  line-height: 24px;
  position: relative;
}

.some-text-goes1 {
  color: var(--text-neutral-secondary-inverse);
}

.some-text-goes-here-parent {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: none;
  position: absolute;
  top: 32px;
  left: 183px;
}

.play1 {
  width: calc(100% - 57.3px);
  height: 116px;
  position: absolute;
  top: 39px;
  left: 29px;
  right: 28.3px;
}

.video {
  width: 347.3px;
  height: 195px;
  position: relative;
}

.hero1 {
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: var(--gap-21xl);
  text-align: left;
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-primary-inverse);
  flex-direction: column;
  display: flex;
}

.why-longevity3 {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #38eb9a, #20beff);
  -webkit-background-clip: text;
  line-height: 40px;
  position: relative;
}

.why-longevity-group {
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: var(--xs);
  flex-direction: column;
  display: flex;
}

.expert-level-knowledge1 {
  justify-content: center;
  align-items: center;
  width: 320px;
  line-height: 28px;
  display: flex;
  position: relative;
}

.frame-parent19 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--l1);
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
}

.button30, .frame-parent19, .why-longevity2 {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.button30 {
  border-radius: var(--m1);
  background-color: var(--text-brand-primary-brand-primary);
  text-align: left;
  font-size: var(--heading-heading-xs-size);
  color: var(--background-neutral-secondary);
  align-items: flex-start;
}

.why-longevity2 {
  align-self: stretch;
  align-items: center;
  gap: var(--xl2);
}

.how-it-works-child {
  width: calc(100% + 85px);
  max-width: 100%;
  height: 664px;
  position: absolute;
  top: 43px;
  left: -42px;
  right: -43px;
  overflow: hidden;
}

.how-it-works4 {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #38eb9a, #20beff);
  -webkit-background-clip: text;
  align-self: stretch;
  line-height: 40px;
  position: relative;
}

.frame-parent20, .how-it-works3 {
  justify-content: flex-start;
  align-items: center;
  gap: var(--xl2);
  flex-direction: column;
  display: flex;
}

.frame-parent20 {
  font-size: var(--heading-heading-m-size);
  color: var(--text-brand-primary-brand-primary);
  align-self: stretch;
}

.how-it-works3 {
  width: calc(100% - 23px);
  position: absolute;
  top: 0;
  left: 12.5px;
  right: 10.5px;
}

.how-it-works2 {
  align-self: stretch;
  height: 707px;
  position: relative;
}

.button32, .hero-parent {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.hero-parent {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-61xl);
}

.button32 {
  border-radius: var(--m1);
  background-color: var(--text-brand-primary-brand-primary);
  text-align: left;
  font-size: var(--heading-heading-xs-size);
  align-items: flex-start;
}

.reliable-and-accurate-group {
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-tertiary);
  padding: var(--xl2);
  align-items: center;
  gap: var(--m);
}

.banners1, .features-card-alt4, .reliable-and-accurate-group {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  display: flex;
}

.banners1 {
  align-items: flex-start;
  gap: var(--l1);
  color: var(--background-neutral-secondary);
}

.features-card-alt4 {
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-tertiary);
  padding: var(--padding-13xl);
  align-items: center;
  gap: var(--m);
}

.features-card-alt-group {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-13xl);
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
  overflow-x: auto;
}

.button34, .features-card-alt-group, .features1 {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.features1 {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-21xl);
  overflow: hidden;
}

.button34 {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-primary-inverse);
  align-items: flex-start;
}

.frame-parent25, .frame-parent26 {
  border-radius: var(--xl);
  align-items: flex-start;
  gap: var(--xl2);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.frame-parent25 {
  padding: var(--m);
  background-image: url("frame-68@3x.82bfb7bd.png");
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  display: flex;
}

.frame-parent26 {
  padding: var(--l1) var(--m);
  background-image: url("frame-36@3x.4d3a1f39.png");
}

.as-a-fitness-enthusiast-i-alw-group, .communitynewsletter, .frame-parent26, .testimonials-group {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  display: flex;
}

.communitynewsletter {
  align-items: flex-start;
  gap: var(--l1);
  text-align: left;
  color: var(--text-neutral-tertiary-inverse);
}

.as-a-fitness-enthusiast-i-alw-group, .testimonials-group {
  padding: 0 var(--padding-xl);
  align-items: center;
  gap: var(--xs);
}

.as-a-fitness-enthusiast-i-alw-group {
  border-top: 1px solid var(--text-brand-primary-brand-primary);
  border-bottom: 1px solid var(--text-brand-primary-brand-primary);
  padding: var(--xl2) 0;
  justify-content: center;
  gap: var(--m);
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
}

.testimonials2 {
  gap: var(--xl2);
  overflow: hidden;
}

.frame-parent18, .testimonials2 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.new-features-designed1 {
  font-size: var(--heading-heading-xs-size);
  text-align: center;
  align-self: stretch;
  line-height: 24px;
  position: relative;
}

.coming-soon-group {
  padding: 0 var(--padding-xl);
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: var(--s);
  flex-direction: column;
  display: flex;
}

.frame-child32 {
  border-radius: var(--xl);
  object-fit: cover;
  flex-shrink: 0;
  align-self: stretch;
  max-width: 100%;
  height: 224px;
  position: relative;
  overflow: hidden;
}

.frame-parent28 {
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--m);
  flex-direction: column;
  display: flex;
}

.frame-parent27 {
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
  align-items: flex-start;
}

.coming-soon2, .frame-parent27, .frame-parent39 {
  justify-content: flex-start;
  align-self: stretch;
  gap: var(--xl2);
  flex-direction: column;
  display: flex;
}

.frame-parent39 {
  border-radius: var(--xl);
  padding: var(--l1) var(--m);
  color: var(--text-neutral-tertiary-inverse);
  background-image: url("frame-361@3x.cd0059eb.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: flex-start;
}

.coming-soon2 {
  text-align: left;
  align-items: center;
}

.nulla-pulvinar-odio8 {
  flex: 1;
  line-height: 28px;
  position: relative;
}

.nulla-pulvinar-odio-vel-dolor-parent2 {
  align-items: flex-start;
  gap: var(--xs);
  flex-direction: row;
}

.faq6, .nulla-pulvinar-odio-vel-dolor-parent2, .nulla-pulvinar-odio-vel-dolor-parent3 {
  justify-content: flex-start;
  align-self: stretch;
  display: flex;
}

.faq6 {
  border-radius: var(--l);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-quaternary);
  padding: var(--m);
  align-items: flex-start;
  gap: var(--gap-5xl);
  flex-direction: column;
}

.nulla-pulvinar-odio-vel-dolor-parent3 {
  align-items: center;
  gap: var(--xs);
  flex-direction: row;
}

.nulla-pulvinar-odio13 {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-tertiary-inverse);
  flex-shrink: 0;
  align-items: center;
  width: 655px;
  height: 72px;
  line-height: 24px;
  display: none;
  position: relative;
}

.faq-group {
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--m);
  text-align: left;
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
  flex-direction: column;
  display: flex;
}

.logo-icon4 {
  flex-shrink: 0;
  width: 140px;
  height: 26px;
  position: relative;
  overflow: hidden;
}

.stay-informed-about4 {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #38eb9a, #20beff);
  -webkit-background-clip: text;
  align-self: stretch;
  line-height: 32px;
  position: relative;
}

.frame-parent41 {
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--xl2);
  flex-direction: column;
  display: flex;
}

.infolongevityac2 {
  color: var(--text-brand-primary-brand-primary);
  text-align: center;
  line-height: 24px;
  text-decoration: underline;
  position: relative;
}

.twitter-container {
  justify-content: center;
  align-items: flex-end;
  gap: var(--s);
  flex-direction: row;
  display: flex;
}

.frame-parent42 {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-primary-inverse);
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.footer4, .frame-parent40 {
  flex-direction: column;
  align-self: stretch;
  display: flex;
}

.frame-parent40 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--xl1);
}

.footer4 {
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  padding: var(--padding-xl);
  justify-content: center;
  align-items: center;
}

.copyright-20241 {
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-quaternary-inverse);
  text-align: center;
  width: 231px;
  line-height: 24px;
  display: inline-block;
  position: relative;
}

.footer3, .homepage, .homepage-mobile {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.footer3 {
  align-self: stretch;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--heading-heading-m-size);
  color: var(--text-neutral-tertiary-inverse);
  display: flex;
}

.homepage, .homepage-mobile {
  background-color: var(--background-neutral-primary);
  box-sizing: border-box;
  overflow: hidden;
}

.homepage-mobile {
  padding: 0 var(--padding-xl);
  gap: var(--gap-61xl);
  text-align: center;
  font-size: var(--heading-heading-l-size);
  width: 375px;
  display: none;
}

.homepage {
  padding: 0 0 var(--padding-13xl);
  gap: var(--gap-181xl);
  text-align: left;
  font-size: var(--heading-heading-2xl-size);
  color: var(--text-brand-primary-brand-primary-light);
  font-family: var(--heading-heading-xs);
  width: 100%;
  display: flex;
  position: relative;
}

@media screen and (width <= 960px) {
  .frame-group {
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
  }

  .rectangle-icon {
    width: 400px;
  }

  .footer1 {
    width: 100%;
    max-width: 920px;
    display: flex;
  }

  .frame-parent14 {
    flex-direction: row;
  }

  .footer2 {
    display: none;
  }
}

@media screen and (width <= 420px) {
  .header {
    display: none;
  }

  .body {
    padding-left: var(--l1);
    padding-right: var(--l1);
    box-sizing: border-box;
  }

  .body, .footer {
    display: none;
  }

  .homepage-mobile {
    align-self: stretch;
    width: auto;
    display: flex;
  }
}
/*# sourceMappingURL=index.8d10e396.css.map */
