.logo-icon {
  width: 177px;
  position: relative;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.button-child {
  width: 0;
  position: relative;
  background-color: var(--text-brand-primary-brand-primary);
  height: 1px;
  z-index: 0;
}
.metamask-icon {
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button1 {
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  font-family: var(--heading-heading-xs);
  color: var(--text-neutral-primary-inverse);
  text-align: left;
}
.metamask-parent {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--xs) var(--l1);
  gap: var(--xs);
  z-index: 1;
}
.button,
.button2 {
  cursor: pointer;
  border: 0;
  background-color: transparent;
}
.button {
  padding: 0 var(--s) var(--xs1);
  width: 124px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  box-sizing: border-box;
  position: relative;
}
.button2 {
  width: 126px;
}
.button2,
.button4,
.button6,
.button8 {
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--s) var(--xs1);
  box-sizing: border-box;
  position: relative;
}
.button4 {
  cursor: pointer;
  border: 0;
  background-color: transparent;
  width: 158px;
}
.button6,
.button8 {
  width: 163px;
}
.button8 {
  cursor: pointer;
  border: 0;
  background-color: transparent;
  width: 96px;
}
.button-parent {
  flex-direction: row;
  align-items: flex-start;
}
.button-parent,
.button10,
.metamask-parent2 {
  display: flex;
  justify-content: flex-start;
}
.metamask-parent2 {
  border-radius: var(--m1);
  border: 1px solid var(--text-brand-primary-brand-primary);
  flex-direction: row;
  align-items: center;
  padding: var(--xs) var(--l1);
  gap: var(--xs);
}
.metamask-parent2:hover {
  border: 1px solid var(--color-mediumseagreen);
  box-sizing: border-box;
}
.button10 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  flex-direction: column;
  align-items: flex-start;
}
.header,
.logo-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-xl) 0;
}
.tired-of-going,
.unlock-your-longevity {
  align-self: stretch;
  position: relative;
  line-height: 64px;
}
.tired-of-going {
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  color: var(--text-brand-primary-brand-primary-light);
}
.hero-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--m);
  max-width: 812px;
}
.button13 {
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  font-family: var(--heading-heading-xs);
  color: var(--background-neutral-secondary);
  text-align: left;
}
.button12,
.metamask-parent3 {
  border-radius: var(--m1);
  display: flex;
  justify-content: flex-start;
}
.metamask-parent3 {
  flex-direction: row;
  align-items: center;
  padding: var(--xs) var(--l1);
  gap: var(--xs);
}
.button12 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--text-brand-primary-brand-primary);
  flex-direction: column;
  align-items: flex-start;
}
.button12:hover {
  background-color: var(--color-mediumseagreen);
}
.video-1-icon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 566px;
  object-fit: cover;
}
.play-child,
.play-item {
  position: absolute;
  border-radius: var(--full);
}
.play-child {
  top: 0;
  left: calc(50% - 58px);
  backdrop-filter: var(--background-blur-xs);
  background-color: var(--background-overlay-white-light);
  width: 116px;
  height: 116px;
}
.play-item {
  top: 10px;
  left: calc(50% - 48px);
  background-color: var(--background-overlay-black-strong);
  border: 2px solid var(--text-brand-primary-brand-primary);
  box-sizing: border-box;
  width: 96px;
  height: 96px;
}
.play-inner {
  position: absolute;
  top: 40px;
  left: calc(50% - 18px);
  border-radius: var(--br-9xs);
  width: 36px;
  height: 36px;
  object-fit: contain;
}
.play {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 225px;
  left: 446px;
  width: 116px;
  height: 116px;
}
.video-1-parent {
  width: 1008px;
  position: relative;
  height: 566px;
}
.header-parent,
.hero {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.hero {
  gap: var(--gap-45xl);
  text-align: center;
  font-size: var(--heading-heading-3xl-size);
}
.header-parent {
  gap: 60px;
}
.health-isnt-one-size-fits-all {
  width: 787px;
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.why-longevity-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--s);
}
.icon-1 {
  width: 64px;
  position: relative;
  height: 64px;
  object-fit: cover;
}
.expert-level-knowledge {
  align-self: stretch;
  height: 32px;
  position: relative;
  line-height: 32px;
  display: inline-block;
}
.frame-child {
  width: 48px;
  position: relative;
  background-color: var(--background-brand-secondary-brand-secondary);
  height: 1px;
}
.information-on-supplements {
  align-self: stretch;
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  color: var(--text-neutral-tertiary-inverse);
}
.icon-1-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--m);
}
.frame-group,
.why-longevity {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--xl1);
  font-size: var(--heading-heading-m-size);
  color: var(--text-neutral-primary-inverse);
}
.why-longevity {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--xl4);
  text-align: center;
  font-size: var(--heading-heading-2xl-size);
  color: var(--text-brand-primary-brand-primary-light);
}
.frame-parent,
.heres-how-our {
  font-size: var(--heading-heading-xs-size);
}
.frame-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 160px;
  color: var(--text-neutral-primary-inverse);
}
.heres-how-our {
  position: relative;
  line-height: 24px;
}
.mask-group-icon {
  position: absolute;
  top: 4px;
  left: calc(50% - 336px);
  width: 687px;
  height: 687px;
  object-fit: cover;
}
.group-icon {
  position: absolute;
  top: 0;
  left: 16px;
  width: 812px;
  height: 696px;
}
.b,
.provide-your-information {
  width: 244px;
  position: relative;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.provide-your-information {
  font-size: var(--heading-heading-s-size);
  line-height: 28px;
  color: var(--text-neutral-primary-inverse);
}
.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--xs1);
}
.frame-child1 {
  width: 48px;
  position: relative;
  background-color: var(--background-brand-secondary-brand-secondary);
  height: 1px;
  display: none;
}
.ask-questions-get {
  width: 279px;
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  color: var(--text-neutral-tertiary-inverse);
  display: none;
  align-items: center;
  justify-content: center;
}
.frame-container {
  position: absolute;
  top: 64px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--s);
}
.b1 {
  align-self: stretch;
  position: relative;
  line-height: 32px;
}
.create-custom-charts {
  width: 279px;
  position: relative;
  font-size: var(--heading-heading-s-size);
  line-height: 28px;
  display: inline-block;
  color: var(--text-neutral-primary-inverse);
}
.frame-parent1,
.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.group {
  align-self: stretch;
  gap: var(--xs1);
}
.frame-parent1 {
  position: absolute;
  top: 512px;
  left: 0;
  gap: var(--s);
}
.receive-highest-quality {
  width: 312px;
  position: relative;
  font-size: var(--heading-heading-s-size);
  line-height: 28px;
  display: flex;
  color: var(--text-neutral-primary-inverse);
  align-items: center;
  justify-content: center;
}
.frame-parent2,
.frame-parent3 {
  position: absolute;
  top: 512px;
  left: 502px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--s);
}
.frame-parent3 {
  top: 64px;
  left: 564px;
}
.mask-group-parent {
  width: 828px;
  position: relative;
  height: 696px;
  font-size: var(--heading-heading-m-size);
  color: var(--text-brand-primary-brand-primary);
}
.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--xl4);
  text-align: center;
}
.reliable-and-accurate {
  align-self: stretch;
  position: relative;
  line-height: 48px;
  font-weight: 600;
  background: linear-gradient(90deg, #38eb9a, #20beff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.reliable-and-accurate-parent {
  align-self: stretch;
  flex: 1;
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-tertiary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--xl2) var(--xl4);
  gap: var(--xl2);
}
.layer-4-icon {
  width: 68px;
  position: relative;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
}
.your-data-is-protected-parent {
  flex: 1;
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-tertiary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--xl2) var(--xl4);
  gap: var(--xl2);
}
.banners {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  text-align: center;
  font-size: var(--heading-heading-xl-size);
}
.ask-learn-live {
  position: relative;
  line-height: 56px;
  background: linear-gradient(90deg, #38eb9a, #20beff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.group-child {
  position: absolute;
  width: calc(100% + 122.1px);
  top: calc(50% - 95.7px);
  right: -74.8px;
  left: -47.3px;
  border-radius: 50%;
  background: radial-gradient(
    50% 50%at 50% 50%,
    rgba(56, 235, 154, 0.75),
    rgba(26, 26, 26, 0)
  );
  height: 218.1px;
  display: none;
}
.talk-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 96px;
  height: 96px;
  object-fit: cover;
}
.ellipse-parent {
  width: 96px;
  position: relative;
  height: 96px;
}
.talk-to-your {
  align-self: stretch;
  position: relative;
  line-height: 28px;
}
.features-card-alt,
.group-parent,
.talk-to-your-ai-coach-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.talk-to-your-ai-coach-parent {
  align-self: stretch;
  gap: var(--s);
}
.features-card-alt,
.group-parent {
  gap: var(--m);
}
.group-parent {
  align-self: stretch;
}
.features-card-alt {
  width: 280px;
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-tertiary);
  box-sizing: border-box;
  height: 373px;
  padding: var(--padding-13xl);
}
.ask-questions-naturally {
  margin: 0;
}
.features-card-alt-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: var(--l1);
  text-align: center;
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
}
.features {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--xl4);
}
.youre-not-alone {
  align-self: stretch;
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
}
.community,
.join-our-community-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.join-our-community-parent {
  gap: var(--s);
}
.community {
  flex: 1;
  border-radius: var(--xl);
  padding: var(--xl1);
  gap: 73px;
  background-image: url(./public/community@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.arrow-buttons-icon {
  width: 48px;
  position: relative;
  height: 40px;
  object-fit: contain;
}
.email-input-child {
  align-self: stretch;
  position: relative;
  background-color: var(--text-neutral-quaternary-inverse);
  height: 1px;
}
.email-input {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--s);
}
.community-parent,
.newsletter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.newsletter {
  flex: 1;
  border-radius: var(--xl);
  flex-direction: column;
  padding: var(--xl1);
  gap: var(--xl2);
  background-image: url(./public/newsletter@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.community-parent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-13xl);
  font-size: var(--heading-heading-xl-size);
  color: var(--text-neutral-tertiary-inverse);
}
.mask-group-icon1 {
  width: 592px;
  position: relative;
  height: 349px;
}
.as-a-fitness,
.sarah-m-fitness-container {
  width: 592px;
  position: relative;
  line-height: 28px;
  display: inline-block;
}
.sarah-m-fitness-container {
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  color: var(--text-neutral-tertiary-inverse);
}
.as-a-fitness-enthusiast-i-alw-parent {
  border-top: 1px solid var(--text-brand-primary-brand-primary);
  border-bottom: 1px solid var(--text-brand-primary-brand-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--xl4) 0;
  gap: var(--xl2);
}
.mask-group-group,
.testimonials {
  display: flex;
  justify-content: flex-start;
}
.mask-group-group {
  flex-direction: row;
  align-items: flex-start;
  gap: 104px;
  text-align: center;
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
}
.testimonials {
  width: 1920px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  gap: var(--xl4);
}
.all-your-health {
  align-self: stretch;
  position: relative;
  line-height: 40px;
}
.all-your-health-data-in-one-pl-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--xl2);
}
.rectangle-icon {
  width: 488px;
  position: relative;
  border-radius: var(--xl);
  height: 300px;
  object-fit: cover;
}
.rectangle-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--xl4);
}
.join-waitlist-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--s);
}
.email-input1,
.newsletter1 {
  display: flex;
  align-items: flex-end;
}
.email-input1 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--s);
}
.newsletter1 {
  align-self: stretch;
  border-radius: var(--xl);
  flex-direction: row;
  justify-content: flex-end;
  padding: var(--xl1);
  gap: var(--xl2);
  background-image: url(./public/newsletter1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: var(--heading-heading-xl-size);
  color: var(--text-neutral-tertiary-inverse);
}
.coming-soon,
.frame-parent4 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent4 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--xl5);
  font-size: var(--heading-heading-l-size);
  color: var(--text-neutral-primary-inverse);
}
.coming-soon {
  width: 100%;
  align-items: center;
  gap: var(--xl4);
  max-width: 1216px;
}
.nulla-pulvinar-odio {
  width: 655px;
  position: relative;
  line-height: 32px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.subtract-icon {
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.nulla-pulvinar-odio-vel-dolor-parent {
  width: 720px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.nulla-pulvinar-odio1 {
  width: 655px;
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  color: var(--text-neutral-tertiary-inverse);
  display: flex;
  align-items: center;
}
.faq1 {
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-quaternary);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-21xl);
  gap: var(--gap-5xl);
}
.nulla-pulvinar-odio3 {
  width: 655px;
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  color: var(--text-neutral-tertiary-inverse);
  display: none;
  align-items: center;
}
.faq-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--heading-heading-m-size);
  color: var(--text-neutral-primary-inverse);
}
.faq {
  align-items: center;
  gap: var(--gap-61xl);
  text-align: center;
}
.body,
.faq,
.frame-parent12 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.body {
  width: 100%;
  align-items: center;
  padding: 0 var(--l1);
  box-sizing: border-box;
  gap: var(--gap-181xl);
  max-width: 1280px;
}
.frame-parent12 {
  width: 477px;
  align-items: flex-start;
  gap: var(--xl2);
}
.logo-icon1 {
  width: 208px;
  position: relative;
  height: 38px;
  overflow: hidden;
  flex-shrink: 0;
}
.get-in-touch,
.infolongevityac {
  position: relative;
  line-height: 28px;
}
.infolongevityac {
  font-size: var(--heading-heading-xs-size);
  text-decoration: underline;
  line-height: 24px;
  color: var(--text-brand-primary-brand-primary);
  text-align: center;
}
.get-in-touch-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--s);
}
.twitter-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: var(--m);
}
.frame-parent13 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--l1);
}
.frame-parent11,
.logo-group {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.logo-group {
  align-self: stretch;
  flex-direction: column;
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
}
.frame-parent11 {
  width: 100%;
  flex-direction: row;
  max-width: 1216px;
}
.footer1 {
  width: 920px;
  border-radius: var(--xl3);
  background-color: var(--background-neutral-secondary);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-21xl);
  box-sizing: border-box;
  max-width: 920px;
}
.frame-parent15 {
  width: 551px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--xl2);
}
.infolongevityac1 {
  text-decoration: underline;
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  color: var(--text-brand-primary-brand-primary);
  text-align: center;
}
.infolongevityac1:hover {
  color: var(--color-mediumseagreen);
}
.vector-icon {
  position: absolute;
  height: 87.5%;
  width: 85%;
  top: 6.25%;
  right: 7.5%;
  bottom: 6.25%;
  left: 7.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.twitter {
  text-decoration: none;
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.vector-icon1,
.vector-icon2 {
  position: absolute;
  height: 92.5%;
  width: 92.5%;
  top: 5%;
  right: 2.5%;
  bottom: 2.5%;
  left: 5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon2 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.frame-parent16 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--xl4);
}
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 151px;
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
}
.frame-parent14 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1216px;
}
.footer2 {
  align-self: stretch;
  border-radius: var(--xl3);
  background-color: var(--background-neutral-secondary);
  height: 417px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-21xl);
  box-sizing: border-box;
  color: var(--text-neutral-tertiary-inverse);
}
.copyright-2024 {
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  color: var(--text-neutral-quaternary-inverse);
}
.footer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-21xl);
  gap: var(--xl2);
  font-size: var(--heading-heading-xl-size);
  color: var(--text-neutral-secondary-inverse);
}
.logo-icon3 {
  width: 132px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.button20,
.button22,
.button24,
.button26 {
  width: 124px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--s) var(--xs1);
  box-sizing: border-box;
  position: relative;
}
.button22,
.button24,
.button26 {
  width: 126px;
}
.button24,
.button26 {
  width: 104px;
}
.button26 {
  width: 96px;
}
.button-group,
.metamask-parent11 {
  flex-direction: row;
  justify-content: flex-start;
}
.button-group {
  display: none;
  align-items: flex-start;
}
.metamask-parent11 {
  border-radius: var(--m1);
  border: 1px solid var(--text-brand-primary-brand-primary);
  display: flex;
  align-items: center;
  padding: var(--xs) var(--l1);
  gap: var(--xs);
}
.button28 {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.menu-child {
  width: 14px;
  position: relative;
  border-radius: var(--br-980xl);
  height: 0;
}
.header1,
.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu {
  width: 16px;
  height: 16px;
  justify-content: center;
  gap: 6px;
}
.header1 {
  align-self: stretch;
  justify-content: flex-start;
  padding: var(--padding-xs) 0;
}
.unlock-your-longevity1 {
  align-self: stretch;
  position: relative;
  line-height: 48px;
  font-weight: 600;
}
.hero-text1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  gap: var(--m);
  text-align: center;
  font-size: var(--heading-heading-xl-size);
}
.video-with-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 347.3px;
  height: 195px;
  object-fit: cover;
}
.play-child1,
.play-child2 {
  position: absolute;
  border-radius: var(--full);
}
.play-child1 {
  width: calc(100% - 210px);
  top: 18px;
  right: 105px;
  left: 105px;
  backdrop-filter: var(--background-blur-xs);
  background-color: var(--background-overlay-white-light);
  height: 80px;
}
.play-child2 {
  width: calc(100% - 224px);
  top: 25px;
  right: 112px;
  left: 112px;
  background-color: var(--background-overlay-black-strong);
  border: 2px solid var(--text-brand-primary-brand-primary);
  box-sizing: border-box;
  height: 66px;
}
.polygon-icon {
  position: absolute;
  width: calc(100% - 262px);
  top: 44px;
  right: 103px;
  left: 159px;
  border-radius: var(--br-9xs);
  max-width: 100%;
  overflow: hidden;
  height: 28px;
  object-fit: contain;
}
.some-text-goes,
.some-text-goes1 {
  position: relative;
  line-height: 24px;
}
.some-text-goes1 {
  color: var(--text-neutral-secondary-inverse);
}
.some-text-goes-here-parent {
  position: absolute;
  top: 32px;
  left: 183px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.play1 {
  position: absolute;
  width: calc(100% - 57.3px);
  top: 39px;
  right: 28.3px;
  left: 29px;
  height: 116px;
}
.video {
  width: 347.3px;
  position: relative;
  height: 195px;
}
.hero1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  text-align: left;
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-primary-inverse);
}
.why-longevity3 {
  position: relative;
  line-height: 40px;
  background: linear-gradient(90deg, #38eb9a, #20beff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.why-longevity-group {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--xs);
}
.expert-level-knowledge1 {
  width: 320px;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.frame-parent19 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--l1);
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
}
.button30,
.frame-parent19,
.why-longevity2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.button30 {
  border-radius: var(--m1);
  background-color: var(--text-brand-primary-brand-primary);
  align-items: flex-start;
  text-align: left;
  font-size: var(--heading-heading-xs-size);
  color: var(--background-neutral-secondary);
}
.why-longevity2 {
  align-self: stretch;
  align-items: center;
  gap: var(--xl2);
}
.how-it-works-child {
  position: absolute;
  width: calc(100% + 85px);
  top: 43px;
  right: -43px;
  left: -42px;
  max-width: 100%;
  overflow: hidden;
  height: 664px;
}
.how-it-works4 {
  align-self: stretch;
  position: relative;
  line-height: 40px;
  background: linear-gradient(90deg, #38eb9a, #20beff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.frame-parent20,
.how-it-works3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--xl2);
}
.frame-parent20 {
  align-self: stretch;
  font-size: var(--heading-heading-m-size);
  color: var(--text-brand-primary-brand-primary);
}
.how-it-works3 {
  position: absolute;
  width: calc(100% - 23px);
  top: 0;
  right: 10.5px;
  left: 12.5px;
}
.how-it-works2 {
  align-self: stretch;
  position: relative;
  height: 707px;
}
.button32,
.hero-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.hero-parent {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-61xl);
}
.button32 {
  border-radius: var(--m1);
  background-color: var(--text-brand-primary-brand-primary);
  align-items: flex-start;
  text-align: left;
  font-size: var(--heading-heading-xs-size);
}
.reliable-and-accurate-group {
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-tertiary);
  align-items: center;
  padding: var(--xl2);
  gap: var(--m);
}
.banners1,
.features-card-alt4,
.reliable-and-accurate-group {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.banners1 {
  align-items: flex-start;
  gap: var(--l1);
  color: var(--background-neutral-secondary);
}
.features-card-alt4 {
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-tertiary);
  align-items: center;
  padding: var(--padding-13xl);
  gap: var(--m);
}
.features-card-alt-group {
  align-self: stretch;
  overflow-x: auto;
  align-items: center;
  gap: var(--gap-13xl);
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
}
.button34,
.features-card-alt-group,
.features1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.features1 {
  align-self: stretch;
  overflow: hidden;
  align-items: center;
  gap: var(--gap-21xl);
}
.button34 {
  align-items: flex-start;
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-primary-inverse);
}
.frame-parent25,
.frame-parent26 {
  border-radius: var(--xl);
  align-items: flex-start;
  gap: var(--xl2);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.frame-parent25 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--m);
  background-image: url(./public/frame-68@3x.png);
}
.frame-parent26 {
  padding: var(--l1) var(--m);
  background-image: url(./public/frame-36@3x.png);
}
.as-a-fitness-enthusiast-i-alw-group,
.communitynewsletter,
.frame-parent26,
.testimonials-group {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.communitynewsletter {
  align-items: flex-start;
  gap: var(--l1);
  text-align: left;
  color: var(--text-neutral-tertiary-inverse);
}
.as-a-fitness-enthusiast-i-alw-group,
.testimonials-group {
  align-items: center;
  padding: 0 var(--padding-xl);
  gap: var(--xs);
}
.as-a-fitness-enthusiast-i-alw-group {
  border-top: 1px solid var(--text-brand-primary-brand-primary);
  border-bottom: 1px solid var(--text-brand-primary-brand-primary);
  justify-content: center;
  padding: var(--xl2) 0;
  gap: var(--m);
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
}
.testimonials2 {
  overflow: hidden;
  gap: var(--xl2);
}
.frame-parent18,
.testimonials2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.new-features-designed1 {
  align-self: stretch;
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  text-align: center;
}
.coming-soon-group {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  gap: var(--s);
}
.frame-child32 {
  align-self: stretch;
  position: relative;
  border-radius: var(--xl);
  max-width: 100%;
  overflow: hidden;
  height: 224px;
  flex-shrink: 0;
  object-fit: cover;
}
.frame-parent28 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--m);
}
.frame-parent27 {
  align-items: flex-start;
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
}
.coming-soon2,
.frame-parent27,
.frame-parent39 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--xl2);
}
.frame-parent39 {
  border-radius: var(--xl);
  align-items: flex-start;
  padding: var(--l1) var(--m);
  background-image: url(./public/frame-361@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  color: var(--text-neutral-tertiary-inverse);
}
.coming-soon2 {
  align-items: center;
  text-align: left;
}
.nulla-pulvinar-odio8 {
  flex: 1;
  position: relative;
  line-height: 28px;
}
.nulla-pulvinar-odio-vel-dolor-parent2 {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--xs);
}
.faq6,
.nulla-pulvinar-odio-vel-dolor-parent2,
.nulla-pulvinar-odio-vel-dolor-parent3 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.faq6 {
  border-radius: var(--l);
  background-color: var(--background-neutral-secondary);
  border: 1px solid var(--border-neutral-quaternary);
  flex-direction: column;
  align-items: flex-start;
  padding: var(--m);
  gap: var(--gap-5xl);
}
.nulla-pulvinar-odio-vel-dolor-parent3 {
  flex-direction: row;
  align-items: center;
  gap: var(--xs);
}
.nulla-pulvinar-odio13 {
  width: 655px;
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  color: var(--text-neutral-tertiary-inverse);
  display: none;
  align-items: center;
  height: 72px;
  flex-shrink: 0;
}
.faq-group {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--m);
  text-align: left;
  font-size: var(--heading-heading-s-size);
  color: var(--text-neutral-primary-inverse);
}
.logo-icon4 {
  width: 140px;
  position: relative;
  height: 26px;
  overflow: hidden;
  flex-shrink: 0;
}
.stay-informed-about4 {
  align-self: stretch;
  position: relative;
  line-height: 32px;
  background: linear-gradient(90deg, #38eb9a, #20beff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.frame-parent41 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--xl2);
}
.infolongevityac2 {
  position: relative;
  text-decoration: underline;
  line-height: 24px;
  color: var(--text-brand-primary-brand-primary);
  text-align: center;
}
.twitter-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: var(--s);
}
.frame-parent42 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: var(--heading-heading-xs-size);
  color: var(--text-neutral-primary-inverse);
}
.footer4,
.frame-parent40 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.frame-parent40 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--xl1);
}
.footer4 {
  border-radius: var(--xl);
  background-color: var(--background-neutral-secondary);
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl);
}
.copyright-20241 {
  width: 231px;
  position: relative;
  font-size: var(--heading-heading-xs-size);
  line-height: 24px;
  color: var(--text-neutral-quaternary-inverse);
  text-align: center;
  display: inline-block;
}
.footer3,
.homepage,
.homepage-mobile {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.footer3 {
  align-self: stretch;
  display: flex;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--heading-heading-m-size);
  color: var(--text-neutral-tertiary-inverse);
}
.homepage,
.homepage-mobile {
  background-color: var(--background-neutral-primary);
  overflow: hidden;
  box-sizing: border-box;
}
.homepage-mobile {
  width: 375px;
  display: none;
  padding: 0 var(--padding-xl);
  gap: var(--gap-61xl);
  text-align: center;
  font-size: var(--heading-heading-l-size);
}
.homepage {
  width: 100%;
  position: relative;
  display: flex;
  padding: 0 0 var(--padding-13xl);
  gap: var(--gap-181xl);
  text-align: left;
  font-size: var(--heading-heading-2xl-size);
  color: var(--text-brand-primary-brand-primary-light);
  font-family: var(--heading-heading-xs);
}
@media screen and (max-width: 960px) {
  .frame-group {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .rectangle-icon {
    width: 400px;
  }
  .footer1 {
    display: flex;
    width: 100%;
    max-width: 920px;
  }
  .frame-parent14 {
    flex-direction: row;
  }
  .footer2 {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .header {
    display: none;
  }
  .body {
    padding-left: var(--l1);
    padding-right: var(--l1);
    box-sizing: border-box;
  }
  .body,
  .footer {
    display: none;
  }
  .homepage-mobile {
    display: flex;
    align-self: stretch;
    width: auto;
  }
}
